import React, { useState } from "react";
import { DatePicker } from "antd";
import styles from "./DatePickerR.module.scss";

export function DatePickerR({ label = "", ...componentProps }) {
  const [dates, setDates] = useState([]);

  const disabledDate = (current) => {
    if (!dates || dates.length === 0 || !dates[0]) {
      return false;
    }

    if (dates.length === 1) {
      const start = dates[0].clone().startOf("day");
      const minAllowed = start.clone().subtract(7, "days").startOf("day");
      const maxAllowed = start.clone().add(7, "days").endOf("day");
      if (current.isBefore(minAllowed) || current.isAfter(maxAllowed)) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className={styles.date_picker_wrapper}>
      <div className={styles.date_picker_title}>{label}</div>

      <DatePicker.RangePicker
        className={styles.date_picker_component}
        allowClear={false}
        onCalendarChange={(val) => setDates(val)}
        disabledDate={disabledDate}
        {...componentProps}
      />
    </div>
  );
}

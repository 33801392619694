import React, { Component, Fragment } from "react";
import moment from "moment";
import { QueryRenderer } from "@cubejs-client/react";
import { Skeleton, Table } from "antd";

import cubejs from "../cube";

import {
  SingleNumber,
  PieChart,
  LineChart,
  HorizontalBarChart as HorizonBarChart,
} from "../components/visualizations";
import YandexMapWrapDetail from "../charts/YandexMapWrapDetail";
import YandexHeatMapWrap from "../charts/YandexHeatMapWrap";
import ExportExcel from "../components/ExportExcel";
import { rangeListClone } from "../constants";
import {
  getSingleValue,
  generatePieData,
  generateLineData,
  generateBarData,
} from "../utils/visualizations";
import { formatDateTime } from "../utils/helpers";
import { SelectList } from "../components/newDesign/SelectList";
import { DatePickerR } from "../components/newDesign/DatePickerClone/DatePickerR";
import { FilterWrapper } from "../components/newDesign/FilterWrapper/FilterWrapper";
import { FilterItem } from "../components/newDesign/FilterItem";
import { TabMainTitle } from "../components/newDesign/TabMainTitle";
import { RowWrapper } from "../components/newDesign/RowWrapper";
import { ChartCard } from "../components/newDesign/ChartCard";
import { RadioGroup } from "../components/newDesign/RadioGroup/RadioGroup";
import { CHART_MAIN_COLOR, PIE_COLORS } from "../components/newDesign/utils";
import { MainWrapper } from "../components/newDesign/MainWrapper";

const cubejsApi = cubejs({ appId: 1 });

class CrimeStatistics extends Component {
  state = {
    filtersData: {
      "CrimeKgf.crname": {
        key: "CrimeKgf.crname",
        title: "Категория",
        options: [],
        values: [],
      },
      "CrimeKgf.organ": {
        key: "CrimeKgf.organ",
        title: "Орган регистрации",
        options: [],
        values: [],
      },
      "CrimeKgf.theHardcode": {
        key: "CrimeKgf.theHardcode",
        title: "Степень тяжести",
        options: [],
        values: [],
      },
    },
    range: [
      moment().subtract(1, "week").utc(6).startOf("day"),
      moment().utc(6).endOf("day"),
    ],
    mapType: "a",
  };

  async componentDidMount() {
    let { filtersData } = this.state;
    const filterNames = Object.keys(filtersData);
    const reqs = filterNames.map((f) => cubejsApi.load({ dimensions: [f] }));
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData = {
        ...filtersData,
        [filterNames[i]]: {
          ...filtersData[filterNames[i]],
          options: res.rawData().map((d) => d[filterNames[i]]),
        },
      };
    });
    this.setState({ filtersData });
  }

  changeRange = (value) =>
    this.setState({
      range: [moment(value), moment()],
    });
  applyFilters = (filter, values) => {
    let { filtersData } = this.state;
    this.setState({
      filtersData: {
        ...filtersData,
        [filter]: {
          ...filtersData[filter],
          values,
        },
      },
    });
  };

  render() {
    const { filtersData, range, mapType } = this.state;
    const filterNames = Object.keys(filtersData);

    let filters = [
      {
        member: "CrimeKgf.dateSover",
        operator: "inDateRange",
        values: range,
      },
    ];
    filterNames.forEach((f) => {
      const filter = filtersData[f];
      if (filter.values.length) {
        filters = [
          ...filters,
          {
            member: f,
            operator: "equals",
            values: filter.values.map((f) => f.toString()),
          },
        ];
      }
    });

    return (
      <MainWrapper>
        <TabMainTitle>
          Криминогенная обстановка на период с{" "}
          <span className="date-period">
            {range[0].format("DD.MM.YYYY")}{" "}
            <span style={{ color: "#fff" }}>по</span>{" "}
            {range[1].format("DD.MM.YYYY")}
          </span>
        </TabMainTitle>

        <FilterWrapper>
          {filterNames.map((f, i) => {
            const filter = filtersData[f];
            return (
              <FilterItem>
                <SelectList
                  label={filter.title + ":"}
                  mode="multiple"
                  onChange={(value) => this.applyFilters(filter.key, value)}
                  size="small"
                  allowClear
                  list={filter.options}
                />
              </FilterItem>
            );
          })}

          <FilterItem>
          <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range });
              }}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>
          <FilterItem>
            <SelectList
              label={"Шаблон:"}
              defaultValue={rangeListClone["Неделя"].toString()}
              onChange={(value) => this.changeRange(value)}
              size="small"
              showKey="title"
              valueKey="id"
              list={Object.entries(rangeListClone).map(([key, value]) => ({
                id: value.toString(),
                title: key,
              }))}
            />
          </FilterItem>
          <FilterItem action>
            <QueryRenderer
              query={{
                dimensions: [
                  "CrimeKgf.crname",
                  "CrimeKgf.theHardcode",
                  "CrimeKgf.article",
                  "CrimeKgf.dateSover.month",
                  "CrimeKgf.dateVozb",
                  "CrimeKgf.udv",
                  "CrimeKgf.organ",
                  "CrimeKgf.street",
                  "CrimeKgf.flat",
                ],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return null;
                return (
                  <ExportExcel
                    filename="Преступления"
                    data={resultSet.rawData()}
                    fields={[
                      {
                        title: "Категория",
                        dataIndex: "CrimeKgf.crname",
                      },
                      {
                        title: "Тяжесть",
                        dataIndex: "CrimeKgf.theHardcode",
                      },
                      {
                        title: "Статья",
                        dataIndex: "CrimeKgf.article",
                      },
                      {
                        title: "Дата совершения УД",
                        dataIndex: "CrimeKgf.dateSover",
                      },
                      {
                        title: "Дата возбуждения УД",
                        dataIndex: "CrimeKgf.dateVozb",
                      },
                      {
                        title: "Номер КУИ",
                        dataIndex: "CrimeKgf.udv",
                      },
                      {
                        title: "Орган",
                        dataIndex: "CrimeKgf.organ",
                      },
                      {
                        title: "Улица",
                        dataIndex: "CrimeKgf.street",
                      },
                      { title: "Дом", dataIndex: "CrimeKgf.flat" },
                    ]}
                  />
                );
              }}
            />
          </FilterItem>
        </FilterWrapper>

        <RowWrapper>
          <ChartCard>
            <QueryRenderer
              query={{
                measures: ["CrimeKgf.count"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <div style={{ padding: 16 }}>
                    <SingleNumber
                      number={+getSingleValue(resultSet, "CrimeKgf.count")}
                      title="Количество преступлений:"
                    />
                  </div>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        <RowWrapper>
          <ChartCard
            title={
              "Общая динамика" +
              filtersData["CrimeKgf.crname"].values.join(", ")
            }
          >
            <QueryRenderer
              query={{
                measures: ["CrimeKgf.count"],
                dimensions: ["CrimeKgf.dateSover.day"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <LineChart
                    loading={!resultSet}
                    id="CrimeKgfCategoryCommon"
                    {...generateLineData(resultSet)}
                    height="400px"
                    scrollbarWidth={75}
                    scrollbarHeight={4}
                    dateAxis
                    rotate
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title="Преступления по категориям">
            <QueryRenderer
              query={{
                measures: ["CrimeKgf.count"],
                dimensions: ["CrimeKgf.crname"],
                filters: filters.filter((f) => f.member !== "CrimeKgf.crname"),
                order: {
                  "CrimeKgf.count": "asc",
                },
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <HorizonBarChart
                    loading={!resultSet}
                    id="CrimeKgfCategory"
                    {...generateBarData(resultSet)}
                    showValues
                    colorsArr={[CHART_MAIN_COLOR]}
                    scrollStart={0.7}
                    scrollEnd={1}
                    wrapLabels
                    height="400px"
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        <RowWrapper>
          <ChartCard title="Преступления по степени тяжести">
            <QueryRenderer
              query={{
                measures: ["CrimeKgf.count"],
                dimensions: ["CrimeKgf.theHardcode"],
                filters: [],
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                // let data = generatePieData(resultSet)
                //   ? generatePieData(resultSet).data.map(el => ({
                //     ...el,
                //     color: specifiedColors[el["CrimeKgf.theHardcode"]]
                //   }))
                //   : [];
                return (
                  <PieChart
                    loading={!resultSet}
                    colorList={PIE_COLORS}
                    id="CrimeKgfHardcode"
                    {...generatePieData(resultSet)}
                    height="400px"
                  />
                );
              }}
            />
          </ChartCard>
          <ChartCard title="Преступления по гос. органу регистрации">
            <QueryRenderer
              query={{
                measures: ["CrimeKgf.count"],
                dimensions: ["CrimeKgf.organ"],
                filters,
                order: {
                  "CrimeKgf.count": "asc",
                },
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                return (
                  <HorizonBarChart
                    loading={!resultSet}
                    id="CrimeKgfOrgan"
                    {...generateBarData(resultSet)}
                    showValues
                    colorsArr={[CHART_MAIN_COLOR]}
                    scrollStart={0.8}
                    scrollEnd={1}
                    wrapLabels
                    height="400px"
                  />
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        <RowWrapper>
          <ChartCard title="Карта">
            <RadioGroup
              defaultValue="a"
              onChange={(value) =>
                this.setState({
                  mapType: value.target.value,
                })
              }
              list={[
                { key: "a", value: "Карта" },
                { key: "b", value: "Тепловая карта" },
              ]}
              style={{ padding: "0 24px" }}
            />
            <QueryRenderer
              query={{
                dimensions: [
                  "CrimeKgf.id",
                  "CrimeKgf.crcode",
                  "CrimeKgf.x",
                  "CrimeKgf.y",
                ],
                renewQuery: true,
                filters,
                timeDimensions: [
                  {
                    dimension: "CrimeKgf.dateSover",
                    dateRange: range,
                    granularity: "month",
                  },
                ],
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return <Skeleton />;
                const rawData = resultSet.rawData();

                return (
                  <Fragment>
                    {mapType === "a" ? (
                      <YandexMapWrapDetail
                        cubejsApi={cubejsApi}
                        showDetails
                        type="CrimeKgf.theHardcode"
                        query={{
                          dimensions: [
                            "CrimeKgf.id",
                            "CrimeKgf.crname",
                            "CrimeKgf.theHardcode",
                            "CrimeKgf.article",
                            "CrimeKgf.dateSover",
                            "CrimeKgf.dateVozb",
                            "CrimeKgf.udv",
                            "CrimeKgf.organ",
                            "CrimeKgf.street",
                            "CrimeKgf.flat",
                          ],
                        }}
                        renderDetail={(items) => (
                          <Table
                            dataSource={items}
                            pagination={false}
                            rowKey="id"
                            size="small"
                          >
                            <Table.Column
                              title="Категория"
                              dataIndex="CrimeKgf.crname"
                              width={100}
                            />
                            <Table.Column
                              title="Тяжесть"
                              dataIndex="CrimeKgf.theHardcode"
                            />
                            <Table.Column
                              title="Статья"
                              dataIndex="CrimeKgf.article"
                            />
                            <Table.Column
                              title="Дата совершения УД"
                              dataIndex="CrimeKgf.dateSover"
                              render={(date) => formatDateTime(date)}
                            />
                            <Table.Column
                              title="Дата возбуждения УД"
                              dataIndex="CrimeKgf.dateVozb"
                              render={(date) => formatDateTime(date)}
                            />
                            <Table.Column
                              title="Номер КУИ"
                              dataIndex="CrimeKgf.udv"
                            />
                            <Table.Column
                              title="Орган"
                              dataIndex="CrimeKgf.organ"
                            />
                            <Table.Column
                              title="Улица"
                              dataIndex="CrimeKgf.street"
                            />
                            <Table.Column
                              title="Дом"
                              dataIndex="CrimeKgf.flat"
                            />
                          </Table>
                        )}
                        data={rawData}
                        idDataKey="CrimeKgf.id"
                        center={[47.1167, 51.8833]}
                        xDataKey="CrimeKgf.x"
                        yDataKey="CrimeKgf.y"
                      />
                    ) : (
                      <YandexHeatMapWrap
                        center={[47.1167, 51.8833]}
                        objects={rawData}
                        idDataKey="CrimeKgf.id"
                        xDataKey="CrimeKgf.y"
                        yDataKey="CrimeKgf.x"
                        dateRange={range}
                        heatMap={true}
                        zoom={11}
                      />
                    )}
                  </Fragment>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>
      </MainWrapper>
    );
  }
}

export default CrimeStatistics;
